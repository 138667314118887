.table td,
.table th {
  text-align: left;
  vertical-align: middle;
  padding: 0.6rem 1.35rem;
}

.table:not(.table-bordered) {
  border-color: transparent;

  thead > tr > th {
    &:first-child {
      border-top-left-radius: 0.25rem;
    }

    &:last-child {
      border-top-right-radius: 0.25rem;
    }
  }

  tbody > tr:last-child > td {
    &:first-child {
      border-bottom-left-radius: 0.25rem;
    }

    &:last-child {
      border-bottom-right-radius: 0.25rem;
    }
  }
}

.table.table-secondary {
  --bs-table-bg: #f6f6f6;
}

.table.table-sm {
  td,
  th {
    padding: 0.1rem 0.5rem;
  }
}
