:root {
  font-size: 16px;

  --primary-color: #c52228;
  --primary-color-ascent: #df1920;
  --secondary-color: #3a3586;
  --secondary-color-ascent: #2f2a7a;
  --second-action-color: #2a292e;
  --second-action-color-ascent: #434247;
  --dark-color-100: #201e33;
  --dark-color-200: #232038;
  --dark-color-300: #2a273b;
  --dark-color-400: #2f2a7a;
  --dark-color-500: #3a3586;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --gray-color: #6c757d;
}

body {
  color: #6c757d;
  font-family: 'Montserrat', sans-serif;
  background-color: #f4f6f9;
  font-weight: 400;
  font-size: 0.925rem;
}

.gap-10 { gap: 10px; }
.gap-20 { gap: 20px; }
.gap-30 { gap: 30px; }
.gap-40 { gap: 40px; }
.gap-50 { gap: 50px; }

.text-right {
  text-align: right;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.main-content {
  * {
    scrollbar-width: auto;
    scrollbar-color: var(--secondary-color) #ffffff;
  }

  *::-webkit-scrollbar {
    width: 6px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--secondary-color);
    border-radius: 10px;
    border: 1px solid #ffffff;
  }
}
